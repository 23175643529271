@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-4
    }
    
    .spannumber{
        @apply text-white border bg-rcblue border-rcblue rounded-3xl
    }
    .subtitlel:before{
        @apply absolute bottom-0 content-[''] w-[50px] h-[1px] bg-rcblue
    }
    .intro:hover{
        @apply  bg-black
    }
    .viewall:before{
        @apply absolute bottom-0 content-[''] w-0 h-[1px] bg-rcblue opacity-0 transition-all ease-in-out duration-[0.4s] invisible
    }
    .viewall:hover:before{
        @apply visible opacity-100 w-[150px]
    }
    .viewall:hover svg{
        @apply ml-4
    }
    .worckprocessbox:hover{
        @apply translate-y-4
    }
    .box-contact:hover .box-contact-icon{
        @apply bg-black
    }
    /* .workingprocess:before{
        @apply absolute bottom-0 left-0 top-0 right-0 content-[''] 
    } */

}